import React from 'react'

import "../../assets/styles/publicaciones.scss"

// comps
import EstadosFinancieros from "../components/EstadosFinancieros"
import GobiernoCorporativo from "../components/GorbiernoCorporativo"

export default function Publicaciones() {
  return (
    <div className="page-publicaciones">
      <div className="container">
        <h1>Publicaciones</h1>
        <div className="two-column-wrapper">
          <EstadosFinancieros />
          <GobiernoCorporativo />
        </div>
        <a
          href="https://bcq.pnl.mybluehost.me/wp-content/uploads/2021/09/derechos-de-los-consumidores-de-seguros.pdf"
          target="_blank"
          className="derecho-consumidores"
          rel="noreferrer">
          Derechos de los consumidores
        </a>
        <div className="blk-estructura container">
          <h2>Directorio ejecutivo</h2>
          <div className="container-tables">
            <table className="left-col-bold">
              <caption>Junta Directiva</caption>
              <tr>
                <td>Presidenta:</td>
                <td>Ilse Aguiar Arias</td>
              </tr>
              <tr>
                <td>Secretaria:</td>
                <td>Carolina Benavides Aguiar</td>
              </tr>
              <tr>
                <td>Tesorero:</td>
                <td>Fernando Jiménez Campos</td>
              </tr>
              <tr>
                <td>Vocal:</td>
                <td>Alberto Carvajal Umaña</td>
              </tr>
              <tr>
                <td>Fiscal:</td>
                <td>Ronald Sanchez Fuentes</td>
              </tr>
            </table>
            <table className="left-col-bold">
              <caption>Auditoria Interna</caption>
              <tr>
                <td>Auditor</td>
                <td>Jeffry Morales Porras</td>
              </tr>
            </table>
            <table className="left-col-bold">
              <caption>Equipo Gerencial</caption>
              <tr>
                <td>Gerencia General:</td>
                <td>Mauricio Benavides Aguiar</td>
              </tr>
              <tr>
                <td>Gerencia Administrativa:</td>
                <td>Fernando Castillo Calderon</td>
              </tr>
              <tr>
                <td>Gerencia Financiera:</td>
                <td>Andrea Benavides Aguiar</td>
              </tr>
            </table>
            <table className="left-col-bold">
              <caption>Comités de Apoyo</caption>
              <tr>
                <td>Auditoría</td>
                <td>Alberto Carvajal Umaña</td>
              </tr>
              <tr>
                <td>Riesgo</td>
                <td>Fernando Jiménez Campos</td>
              </tr>
              <tr>
                <td>Cumplimiento</td>
                <td>Carolina Benavides Aguiar</td>
              </tr>
            </table>
            <table>
              <caption>Auditoría Externa</caption>
              <tr>
                <td>Despacho Carvajal & Colegiados Contadores Públicos Autorizados</td>
              </tr>
            </table>
          </div>
          <hr />
          <h2>Estructura Social</h2>
          <div className="container-tables">
            <table>
              <tr>
                <th>Accionista</th>
                <th>Participación</th>
              </tr>
              <tr>
                <td>Ilse Aguiar Arias</td>
                <td>52,00%</td>
              </tr>
              <tr>
                <td>Mauricio Benavides Aguiar</td>
                <td>23,20%</td>
              </tr>
              <tr>
                <td>Carolina Benavides Aguiar</td>
                <td>12,40%</td>
              </tr>
              <tr>
                <td>Andrea Benavides Aguiar</td>
                <td>12,40%</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
