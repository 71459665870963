import React, { useState, useEffect } from 'react'
import axios from 'axios'
import _ from "lodash"

// assets
import { AiFillFilePdf as IcoPdf } from "react-icons/ai";
import spinner from '../../../assets/images/spinner.svg';

import "./gobierno-corporativo.scss"

export default function GobiernoCorporativo() {
  const [loading, setLoading] = useState(true)
  const [efList, setEfList] = useState(null)
  const [efItem, setEfItem] = useState(null)

  const [err, setErr] = useState(null)

  useEffect(() => {
    async function getGobiernoCorporativo() {
      try {
        const response = await axios.get('https://bcq.pnl.mybluehost.me/wp-json/wp/v2/gobierno_corporativo');
        const totalPages = response.headers['x-wp-totalpages'] ? Number(response.headers['x-wp-totalpages']) : 1
        let currentPage = 1
        let listData = [...response.data]
        while (currentPage < totalPages) {
          currentPage++
          try {
            const resNewPage = await axios.get(`https://bcq.pnl.mybluehost.me/wp-json/wp/v2/gobierno_corporativo?page=${currentPage}`);
            listData = [...listData, ...resNewPage.data]
          } catch (error) {
            return setErr(true)
          }
        }
        const sortedList = _.sortBy(listData, [(o) => Number(o.acf.anno)]).reverse();
        setEfList(sortedList)
        return setLoading(false)
      } catch (error) {
        setErr(true)
      }
    }
    getGobiernoCorporativo()
  }, [])

  const handleEfItem = async (id) => {
    if (_.isEmpty(id)) return null
    setLoading(true)
    try {
      const response = await axios.get(`https://bcq.pnl.mybluehost.me/wp-json/acf/v3/gobierno_corporativo/${id}`);
      if (!response.data) return null
      const { anno, ...rest } = response.data.acf
      const data = _.reduce(rest, (acc, value) => {
        if (value) acc.push(value)
        return acc
      }, [])
      setEfItem(data)
      setLoading(false)
    } catch (error) {
      setErr(true)
    }
  }

  const handleEfList = () => {
    if (!efList) return null
    return (
      <div className="gobierno_corporativo-list">
        <h3>Lista de Informes de Gobierno Corporativo por año.</h3>
        <div>
          <select className="custom-select"
            onBlur={(e) => handleEfItem(e.target.value)}
            onChange={(e) => handleEfItem(e.target.value)}
          >
            <option value="">Seleccione un año</option>
            {_.map(efList, ({ acf, id }) => {
              if (acf.anno) {
                return (
                  <option key={id} value={id}>{acf.anno}</option>
                )
              }
            })}
          </select>
        </div>
      </div>
    )
  }

  const handleDisplayEfItem = () => {
    if (!efItem || loading) return null
    const items = _.map(efItem, value => {
      return <li key={value.id}><a href={value.url} target="_blank" rel="noreferrer"><IcoPdf /> {value.title}</a></li>
    })
    return (
      <ul className="list-downloads">{items}</ul>
    )
  }

  const handleLoading = () => loading && <img width={60} src={spinner} alt="Cargando" />

  if (err) {
    return (
      <div style={{ marginBottom: 180 }}>Ha ocurrido un error.</div>
    )
  }

  return (
    <div className="blk-gobierno-corporativo">
      <h2>Gobierno Corporativo</h2>
      {handleEfList()}
      {handleDisplayEfItem()}
      {handleLoading()}
    </div>
  )
}
